<template>
  <layout width="420">
    <template slot="header">
      {{ title }}
    </template>

    <template>
      <validation-observer ref="form">
        <v-form>
          <form-text v-if="!edit">
            The geozone will be created in the center of the current map
            position. Its position may be changed from the edit item menu.
          </form-text>
          <form-subheader title="General info" />
          <validation-provider
            v-slot="{ errors }"
            name="Geozone name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Geozone name"
              :error-messages="errors"
            />
          </validation-provider>
          <form-color-selector v-model="state.color" label="Color" />
          <form-subheader title="Position" />
          <v-select
            v-model="state.geotagId"
            :items="geotagList"
            item-text="name"
            item-value="id"
            label="Geotag"
          />
          <form-subheader title="Image" />
          <image-uploader
            :src="imageUrl"
            @change="handleChangeImage"
            @clear="handleClearImage"
          />
          <form-subheader title="Description" />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
          />
        </v-form>
      </validation-observer>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        {{ submitBtnTitle }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { geozoneService } from '@/modules/geozones/api';
import { imageService, objectPropertyService } from '@/modules/common/api';
import { mediaClient } from '@/provider';
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { geotagservice } from '@/modules/geotags/api';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'GeozoneEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    geozoneId: {
      type: String,
      default: ''
    },
    defaultSize: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit, root }) {
    const state = ref({
      schemaId: '',
      name: '',
      description: '',
      imageId: '',
      color: 'default',
      geotagId: ''
    });

    const form = ref(null);
    const geozone = ref({});
    const { list: geotagList, currentGeotagExceptAllItem } = useGeotags();

    const edit = computed(() => props.geozoneId);
    const title = computed(() => (edit.value ? 'Edit geozone' : 'Add geozone'));
    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));

    const imageLoaded = ref(false);

    const imageUrl = computed(() =>
      mediaClient.getImageUrl(state.value.imageId)
    );

    const handleChangeImage = async e => {
      console.log(e);
      state.value.imageId = await imageService.upload(e.target.files[0]);
    };

    const handleClearImage = () => {
      state.value.imageId.value = '';
      imageLoaded.value = false;
    };

    const handleSubmit = async () => {
      form.value.validate().then(async success => {
        if (!success) {
          return;
        }

        if (!edit.value) {
          // create new geozone
          geozone.value = await geozoneService.create({
            name: state.value.name,
            description: state.value.description,
            schemaId: state.value.schemaId
          });
          await objectPropertyService.updateValues([
            {
              id: geozone.value.positionPoints.id,
              value: props.defaultSize
            }
          ]);
        } else {
          await geozoneService.update(props.geozoneId, {
            name: state.value.name,
            description: state.value.description
          });
        }
        await objectPropertyService.updateValues([
          {
            id: geozone.value.infoImageId.id,
            value: state.value.imageId
          },
          {
            id: geozone.value.infoColor.id,
            value: state.value.color
          },
          {
            id: geozone.value.positionGeotagId.id,
            value: state.value.geotagId
          }
        ]);
        geotagservice.updateLinkToObject(
          geozone.value.id,
          geozone.value.positionGeotagId.value,
          state.value.geotagId
        );

        if (!edit.value) {
          root.$router.push({
            name: 'geozone_card',
            params: {
              geozoneId: geozone.value.id
            }
          });
        }

        emit('close');
      });
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      if (!edit.value) {
        state.value.schemaId = await geozoneService.fetchGeozoneSchemaId();
        if (!state.value.schemaId) {
          console.warn(`Can't fetch geozone schema`);
        }
        state.value.geotagId = currentGeotagExceptAllItem.value.id;
      } else {
        geozone.value = await geozoneService.fetch(props.geozoneId);

        const {
          infoImageId,
          infoColor,
          name,
          description,
          positionGeotagId
        } = geozone.value;

        state.value = {
          ...state.value,
          imageId: infoImageId.value,
          color: infoColor.value,
          geotagId: positionGeotagId.value,
          name,
          description
        };
      }
    });

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      imageUrl,
      handleClearImage,
      handleChangeImage,
      edit,
      form,
      geotagList
    };
  }
};
</script>
